import React, { useState, useEffect } from 'react';
import { deviceType } from 'react-device-detect';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { RxArrowLeft, RxChevronRight } from "react-icons/rx";

const RecentPosts = () => {
    const [recentProjects, setRecentProjects] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects`);
                const data = response.data;

                if (data && data.posts) {
                    const sortedPosts = data.posts.sort((a, b) => a.order - b.order);
                    setRecentProjects(sortedPosts);
                }
            } catch (err) {
                setError('Error fetching posts');
            }
        };
        fetchPosts();
    }, []);

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };

    const CustomLeftArrow = ({ onClick }) => {
        return (
            <div
                className="cursor-pointer absolute left-4"
                onClick={onClick}>
                <RxArrowLeft className='text-crystal-cut drop-shadow-2xl' size="0em" />
            </div>
        );
    }

    const CustomRightArrow = ({ onClick }) => {
        return (
            <div
                className="cursor-pointer absolute right-6"
                onClick={onClick}>
                <RxChevronRight className='text-crystal-cut drop-shadow-2xl' size="2.5em" />
            </div>
        );
    }


    return (
        <div className="overflow-hidden h-full pl-8 lg:pl-24">
            {error && <p className="text-red-500 pl-24">{error}</p>}
            <Carousel
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                containerClass="carousel-container  h-full "
                sliderClass="h-full"
                deviceType={deviceType}
                removeArrowOnDeviceType={[""]}
                itemClass="pr-4"
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}>
                {recentProjects.map((project, index) => (
                    <div key={index} className="w-full h-full relative overflow-hidden bg-transparent">
                        <Link className="group/projectItem" to={`/project/${project.slug}`}>
                            <img
                                src={project.imageUrl ? process.env.REACT_APP_API_URL + project.imageUrl : ''}
                                alt={project.title}
                                className="object-cover w-full h-full transition-transform duration-500 brightness-[0.8] ease-in-out transform lg:group-hover/projectItem:scale-105  group-hover/projectItem:brightness-100 pointer-events-none" />
                            <div className="absolute bottom-0 p-8 lg:p-12 text-white">
                                <p className="text-l mb-6">{project.description}</p>
                                <p className="legend font-bold text-xl "><span dangerouslySetInnerHTML={{ __html: project.title }} /></p>
                            </div>
                        </Link>
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

export default RecentPosts;