import { useEffect } from 'react';

const useDocumentTitle = (title) => {
    useEffect(() => {
        // Set the title of the current page
        document.title = (title && `${process.env.REACT_APP_SITE_TITLE} | ${title}`) || process.env.REACT_APP_SITE_TITLE;
        
        // Optionally, if you want to reset the title when component is unmounted
        return () => {
            document.title = process.env.REACT_APP_SITE_TITLE;
        }
    }, [title]);
}

export default useDocumentTitle;