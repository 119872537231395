import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useToast } from '../../ToastContext';
import axios from 'axios';
import slugify from 'slugify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import ImageForm from '../postEditor/ImageForm';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import ConfirmDialog from '../ConfirmDialog';
import AdminHeader from './../adminPage/AdminHeader';


const PostEditor = () => {
    const [username, setUsername] = useState('');

    useEffect(() => {
        const usernameFromStorage = localStorage.getItem('username');
        setUsername(usernameFromStorage);
    }, []);

    useDocumentTitle();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const { addToast } = useToast();

    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState('');
    const [content, setContent] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [description, setDescription] = useState('');
    const [year, setYear] = useState('');
    const [area, setArea] = useState('');
    const [type, setType] = useState('');
    const [planBeforeUrl, setPlanBeforeUrl] = useState('');
    const [planAfterUrl, setPlanAfterUrl] = useState('');
    const [images, setImages] = useState([]);
    const [status, setStatus] = useState('draft');
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const { postId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPost = async () => {
            if (postId) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts/${postId}`, {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    const post = response.data.post;
                    setTitle(post.title);
                    setSlug(post.slug);
                    setContent(post.content);
                    setImageUrl(post.imageUrl);
                    setDescription(post.description);
                    setYear(post.year);
                    setArea(post.area);
                    setType(post.type);
                    setPlanBeforeUrl(post.planBeforeUrl);
                    setPlanAfterUrl(post.planAfterUrl);

                    setImages(() => {
                        const img = post.images.sort((a, b) => a.position - b.position) || []
                        return img;
                    }); // fetch and set images from response
                    setStatus(post.status);
                    setIsEditing(true);
                } catch (e) {
                    setError('Error fetching the post');
                    addToast(e.message, "error", false);
                }
            }
        };
        fetchPost();
    }, [postId, addToast]);

    const handleImageUpload = async (e) => {
        const files = Array.from(e.target.files);
        const formData = new FormData();
        files.forEach((file, index) => {
            formData.append(e.target.name, file);
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/posts/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            const fieldName = e.target.name; // get the name of the input field
            if (response.data.urls && response.data.urls[fieldName]) {
                if (fieldName === 'imageUrl') {
                    setImageUrl(response.data.urls[fieldName][0]); // assume there's only one image for each field
                } else if (fieldName === 'planBeforeUrl') {
                    setPlanBeforeUrl(response.data.urls[fieldName][0]);
                } else if (fieldName === 'planAfterUrl') {
                    setPlanAfterUrl(response.data.urls[fieldName][0]);
                } else if (fieldName === 'imagesGridUrl') {
                    const newImages = response.data.urls[fieldName].map((url, index) => ({
                        imageUrl: url,
                        description: '',
                        gridSize: '1/4',
                        position: images.length + index, // We use '+ index' here to ensure that each new image gets a unique position
                    }));

                    setImages(prevImages => [...(prevImages || []), ...newImages]);
                }
            }

        } catch (e) {
            console.error(e);
            addToast(e.message, "error", false);
        }
    };

    const moveImage = useCallback((dragIndex, hoverIndex) => {
        const dragImage = images[dragIndex];
        setImages(prevImages => {
            const updatedImages = update(prevImages, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragImage],
                ],
            }).map((img, index) => ({ ...img, position: index }));
            return updatedImages;
        });
    }, [images]);

    const handleDelete = async () => {
        setDialogOpen(true);

    };
    const handleDeleteConfirm = async () => {

        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/posts/${postId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            addToast("Пост удалён", "success", true, () => { navigate('/admin'); });
        } catch (e) {
            console.error(e);
            addToast("Пост не удалён", "error", false);
        }
        setDialogOpen(false);

    }

    const handleImageDelete = async (index) => {
        try {
            const newImages = [...images];
            newImages.splice(index, 1);
            setImages(newImages);
        } catch (e) {
            console.error('Error deleting file', e);
            addToast('Error deleting file', "error", false);
        }
    };

    const handleImageGridSizeChange = (id, newSize) => {
        setImages((prevImages) =>
            prevImages.map((image, imageid) =>
                imageid === id ? { ...image, gridSize: newSize } : image
            )
        );
    };

    const handleImageDescriptionChange = (id, newDescription) => {
        setImages((prevImages) =>
            prevImages.map((image, imageid) =>
                imageid === id ? { ...image, description: newDescription } : image
            )
        );
    }

    const handleSubmit = async (e, status) => {
        e.preventDefault();

        // const slug = slugify(title, { 
        //     lower: true, 
        //     strict: true,
        //     remove: /<[^>]+>/g
        // });
        const postData = { title, slug, content, imageUrl, description, year, area, type, planBeforeUrl, planAfterUrl, status, images };

        try {
            if (isEditing) {
                await axios.put(`${process.env.REACT_APP_API_URL}/api/posts/${postId}`, postData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } else {
                await axios.post(`${process.env.REACT_APP_API_URL}/api/posts`, postData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            }

            addToast("Пост сохранён", "success", true, () => { });
            navigate('/admin');
        } catch (e) {
            console.log(e)
            setError('Error saving the post');
            addToast('Error saving the post', "error", false);
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <AdminHeader username={username} />
            <div className="px-12 py-6  mx-auto">
                <div className='flex items-center'>
                    <h2 className="text-2xl font-semibold my-4">{isEditing ? 'Редактирование поста' : 'Новый пост'}</h2>
                    {isEditing && <p className='mx-6 text-gray-400 italic'>{status}</p>}
                </div>
                {error && <p className="text-red-500">{error}</p>}
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                            Заголовок
                        </label>
                        <input
                            id="title"
                            type="text"
                            placeholder="Title"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value)
                                setSlug(slugify(e.target.value.replace(/<[^>]+>/g, ''), {
                                    lower: true,
                                    strict: true
                                }));
                            }}
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>

                    <div>
                        <label htmlFor="slug" className="block text-sm font-medium text-gray-700">
                            URL
                        </label>
                        <input
                            id="slug"
                            type="text"
                            value={slug}
                            disabled
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md" />
                    </div>

                    <div>
                        <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                            Короткое описание (для главной страницы)
                        </label>
                        <textarea
                            id="description"
                            placeholder="Small description for preview"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="mt-1 w-full h-full p-2 border border-gray-300 rounded-md"
                        />
                    </div>


                    <div>
                        <label htmlFor="imageUrl" className="block text-sm font-medium text-gray-700">
                            Главное изображение
                        </label>
                        <input
                            id="imageUrl"
                            type="file"
                            name="imageUrl"
                            onChange={handleImageUpload}
                            className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                        />
                        {imageUrl && <img src={process.env.REACT_APP_API_URL + imageUrl} alt="" className="mt-3 object-cover max-h-full w-full rounded-md" />}
                    </div>


                    <div className="grid grid-cols-2 gap-4">

                        <div className='flex flex-col'>
                            <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                                Описание проекта
                            </label>
                            <textarea
                                id="content"
                                placeholder="Content"
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                className="mt-1 w-full grow p-2 border border-gray-300 rounded-md"
                            />
                        </div>
                        <div>
                            <div>
                                <label htmlFor="year" className="block text-sm font-medium text-gray-700">
                                    Год реализации
                                </label>
                                <input
                                    type="text"
                                    id="year"
                                    placeholder="Year"
                                    value={year}
                                    onChange={(e) => setYear(e.target.value)}
                                    className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                                />
                            </div>

                            <div>
                                <label htmlFor="area" className="block text-sm font-medium text-gray-700">
                                    Площадь
                                </label>
                                <input
                                    id="area"
                                    type="number"
                                    placeholder="Area"
                                    value={area}
                                    onChange={(e) => setArea(e.target.value)}
                                    className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                                />
                            </div>

                            <div>
                                <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                                    Тип (Однокомнатная квартира / дом / офис)
                                </label>
                                <input
                                    id="type"
                                    type="text"
                                    placeholder="Type"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="planBeforeUrl" className="block text-sm font-medium text-gray-700">
                                Планировка до
                            </label>
                            <input
                                id="planBeforeUrl"
                                type="file"
                                name="planBeforeUrl"
                                onChange={handleImageUpload}
                                className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                            />
                            {planBeforeUrl && <img src={process.env.REACT_APP_API_URL + planBeforeUrl} alt="" className="mt-3 object-cover h-36 w-full rounded-md" />}
                        </div>

                        <div>
                            <label htmlFor="planAfterUrl" className="block text-sm font-medium text-gray-700">
                                Планировка после
                            </label>
                            <input
                                id="planAfterUrl"
                                type="file"
                                name="planAfterUrl"
                                onChange={handleImageUpload}
                                className="mt-1 w-full p-2 border border-gray-300 rounded-md"
                            />
                            {planAfterUrl && <img src={process.env.REACT_APP_API_URL + planAfterUrl} alt="" className="mt-3 object-cover h-36 w-full rounded-md" />}
                        </div>
                    </div>

                    <label htmlFor="imagesGridUrl" className="block text-sm font-medium text-gray-700">
                        Изображения
                    </label>
                    <input type="file" name="imagesGridUrl" multiple="multiple" onChange={handleImageUpload} />

                    <div className="flex flex-wrap">
                        {images?.map((image, index) => (
                            <ImageForm
                                key={image.imageUrl}
                                index={index}
                                id={index}
                                text={image.description}
                                moveImage={moveImage}
                                imageUrl={image.imageUrl}
                                gridSize={image.gridSize}
                                handleGridSizeChange={handleImageGridSizeChange}
                                handleDelete={handleImageDelete}
                                handleDescriptionChange={handleImageDescriptionChange}
                            />
                        ))}
                    </div>

                    <div className="flex justify-start">
                        <button
                            type="button"
                            onClick={(e) => handleSubmit(e, 'draft')}
                            className="py-2 px-4 mx-0.5 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                        >
                            Сохранить как черновик
                        </button>
                        <button
                            type="button"
                            onClick={(e) => handleSubmit(e, 'published')}
                            className="py-2 px-4 mx-0.5 border border-transparent text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600"
                        >
                            Сохранить и опубликовать
                        </button>
                        {isEditing &&
                            <button
                                type="button"
                                onClick={handleDelete}
                                className="py-2 px-4 mx-0.5 border border-transparent text-sm font-medium rounded-md text-white bg-red-500 hover:bg-red-600"
                            >
                                Удалить
                            </button>
                        }
                    </div>
                </form>
            </div>
            <ConfirmDialog
                isOpen={isDialogOpen}
                title="Удалить проект"
                message="Вы уверены что хотите удалить проект?"
                onConfirm={handleDeleteConfirm}
                onCancel={() => setDialogOpen(false)}
            />
        </DndProvider>
    );
};

export default PostEditor;
