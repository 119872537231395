import React, { useState, useEffect } from 'react';
import { useToast } from './../../ToastContext';
import axios from 'axios';
import { EditorProvider, Editor, Toolbar, BtnNumberedList, BtnBulletList } from 'react-simple-wysiwyg';
import './ServiceEditForm.css';
import ConfirmDialog from './../ConfirmDialog';
import { FaDeleteLeft } from 'react-icons/fa6';


const ServiceEditForm = () => {
    const { addToast } = useToast();
    const [services, setServices] = useState([]);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [currentIndexToRemove, setCurrentIndexToRemove] = useState(null);



    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services`);
                if (response.data.servicesContents) setServices(response.data.servicesContents);
            } catch (e) {
                console.log(e)
                addToast(e.message, "error", false);
            }
        };
        fetchServices();
    }, [addToast]);

    useEffect(() => {
        // Get the editor's content container
        const editorContainers = document.querySelectorAll(".rsw-ce");

        editorContainers.forEach(editorContent => {
            // Add 'list-disc' class to unordered lists
            const uls = editorContent.querySelectorAll("ul");
            uls.forEach(ul => {
                ul.classList.add("list-disc");
                ul.style.listStylePosition = "inside"; // This is needed because of how some WYSIWYG editors structure their list items
            });

            // Add 'list-decimal' class to ordered lists
            const ols = editorContent.querySelectorAll("ol");
            ols.forEach(ol => {
                ol.classList.add("list-decimal");
                ol.style.listStylePosition = "inside"; // This is needed because of how some WYSIWYG editors structure their list items
            });
        });
    });


    const handleServiceChange = (index, field) => (e) => {
        const newServices = [...services];
        newServices[index][field] = e.target ? e.target.value : e;
        setServices(newServices);
    };

    const handleAddService = () => {
        setServices([...services, { name: '', description: '', price: 0, priceUnit: '' }]);
    };

    const handleRemoveService = (indexToRemove) => {
        setCurrentIndexToRemove(indexToRemove); // Save the index for later
        setDialogOpen(true); // Open the dialog
    };

    const handleRemoveServiceConfirm = async () => {

        if (services[currentIndexToRemove].id) {
            try {
                await axios.delete(`${process.env.REACT_APP_API_URL}/api/services/${services[currentIndexToRemove].id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
            } catch (e) {
                console.log(e)
                addToast(e.message, "error", false);
                return false;
            }
        }
        const updatedServices = services.filter((_, index) => index !== currentIndexToRemove);
        setServices(updatedServices);
        setDialogOpen(false);  // Close the dialog
        addToast("Сервис удалён", "success", true);
    }

    const handleSave = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/services/`, services, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
        } catch (e) {
            console.log(e)
            addToast(e.message, "error", false);
            return false;
        }
        console.log('Services updated');
        addToast("Сервисы сохранены", "success", true);
    };

    return (
        <div className='grid grid-cols-2 gap-10'>
            {services.map((service, index) => (
                <div className="my-1 grid grid-cols-3 gap-4" key={index}>
                    <div className='col-span-3'>
                        <div className='flex justify-between items-center'>
                            <label htmlFor="serviceName" className="block text-sm font-medium text-gray-700">
                                Услуга
                            </label>
                            <button
                                className=" text-red-500 hover:text-red-700 font-bold  py-2 ml-4 rounded focus:outline-none focus:shadow-outline"
                                title="Удалить"
                                onClick={() => handleRemoveService(index)}>
                                <FaDeleteLeft size='1.6em' />
                            </button>
                        </div>
                        <input
                            id='serviceName'
                            className='mt-1 w-full grow p-2 border border-gray-300 rounded-md'
                            value={service.name}
                            onChange={handleServiceChange(index, 'name')}
                        />
                    </div>
                    <div className='col-span-3'>
                        <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                            Описание
                        </label>
                        <EditorProvider>
                            <Editor
                                id={`serviceDescriptionEditor-${index}`}
                                value={service.description}
                                onChange={handleServiceChange(index, 'description')}
                            >
                                <Toolbar >
                                    <BtnNumberedList />
                                    <BtnBulletList />
                                </Toolbar>
                            </Editor>
                        </EditorProvider>
                    </div>

                    <div className='col-span-2'>
                        <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                            Цена
                        </label>
                        <input
                            className='mt-1 w-full grow p-2 border border-gray-300 rounded-md'
                            value={service.price}
                            type="number"
                            onChange={handleServiceChange(index, 'price')}
                        />
                    </div>
                    <div>
                        <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                            &#8381; /
                        </label>
                        <input
                            className='mt-1 w-full grow p-2 border border-gray-300 rounded-md'
                            value={service.priceUnit}
                            onChange={handleServiceChange(index, 'priceUnit')}
                        />
                    </div>

                </div>
            ))}
            <div className='mt-12'>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleAddService}>
                    Добавить
                </button>
                <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mr-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={handleSave}>
                    Сохранить
                </button>
            </div>

            <ConfirmDialog
                isOpen={isDialogOpen}
                title="Удалить услугу"
                message="Вы уверены что хотите удалить услугу?"
                onConfirm={handleRemoveServiceConfirm}
                onCancel={() => setDialogOpen(false)}
            />
        </div>
    );
}

export default ServiceEditForm;
