import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import { ToastProvider } from './ToastContext';
import AnimatedCursor from './components/AnimatedCursor';
import Toast from './components/Toast';
import MainPage from './components/screens/MainPage';
import ProjectPage from './components/screens/ProjectPage';
import LoginPage from './components/screens/LoginPage';
import AdminPage from './components/screens/AdminPage';
import PostEditor from './components/screens/PostEditor';

function isTokenValid() {
    const token = localStorage.getItem('token');

    if (!token) {
        return false;
    }

    const decodedToken = jwt_decode(token);
    const expiryTime = decodedToken.exp * 1000;
    const currentTime = new Date().getTime();

    // Check if the current time is past the token's expiry time
    if (currentTime > expiryTime) {
        localStorage.removeItem('token');
        return false;
    }

    return true;
}

function PrivatePageWrapper({ children }) {
    return isTokenValid() ? children : <Navigate to="/login" replace />;
}

const App = () => {
    
    const ConditionalCursor = () => {
        const location = useLocation();
        const isOnAdminRoute = location.pathname.startsWith('/admin');
    
        if (isMobile || isOnAdminRoute) return null;
    
        return <AnimatedCursor />;
    }


    return (
        <ToastProvider>
            
            <Router>
                <ConditionalCursor />
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/project/:projectSlug?" element={<ProjectPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/admin" element={<PrivatePageWrapper><AdminPage /></PrivatePageWrapper>} />
                    <Route path="/admin/post/:postId?" element={<PrivatePageWrapper><PostEditor /></PrivatePageWrapper>} />
                </Routes>
            </Router>
            <Toast />
        </ToastProvider>
    );
};

export default App;
