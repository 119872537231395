import React, {useEffect, useState} from 'react';
import axios from 'axios';

// const services = [
//     {
//         title: "Дизайн-проект интерьера",
//         price: "4000",
//         priceUnit: "м<sup>2</sup>",
//         description: "Включает в себя:<ul class=\"list-disc\"><li>3D-визуализации всех помещений</li><li>Рабочая документация: план расстановки мебели, планы полов, потолков и инженерных систем, развертки стен, ведомости материалов;</li><li>Подбор материалов, сантехники, освещения</li><li>Приблизительная смета работ и материалов</li></ul>"
//     },
//     {
//         title: "Проект планировки",
//         price: "2000",
//         priceUnit: "м<sup>2</sup>",
//         description: "Включает в себя:<ul class=\"list-disc\"><li>Обмерочный план;</li><li>Демонтажный план;</li><li>Монтажный план;</li><li>План потолков и освещения;</li><li>Планы электрики и инженерных систем;</li><li>План расстановки мебели.</li></ul>"
//     },
//     {
//         title: "Авторский надзор",
//         price: "5000",
//         priceUnit: "час",
//         description: "Авторский надзор — это процесс контроля за точным выполнением проекта исполнителями. Наша задача — урегулирование конфликтных ситуаций на строительной площадке, обновление проектных документов и, при необходимости, поиск альтернативных материалов."
//     },
//     {
//         title: "Консультация дизайнера",
//         price: "5000",
//         priceUnit: "час",
//         description: "Наши дизайнеры предоставляют консультации, помогая вам с организацией пространства и дизайном интерьера вашей квартиры."
//     }
// ]

const Services = () => {
    const [services, setServices] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchServices = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services`);
            const data = response.data;
    
            if (data && data.servicesContents) {
                setServices(data.servicesContents);
            }
          } catch (err) {
            setError('Error fetching services');
          }
        };
        fetchServices();
    }, []);
    return (
        <div className="grid gap-6 lg:grid-cols-2 grid-cols-1">
            {error && <p className="text-red-500 pl-24">{error}</p>}
            {services.map((service,index) =>(
                <div className="rounded-md border border-stone-700 flex flex-col justify-between p-8 lg:p-12 min-h-[25em]" key={index}>
                    <div>
                        <p className="font-bold text-3xl lg:text-4xl ">{service.name}</p>
                        <div className="mt-12" dangerouslySetInnerHTML={{ __html: service.description }} />
                    </div>
                    <div className="flex flex-row mt-12  place-self-end">
                        <div className="font-bold text-2xl mr-2">{service.price}</div>
                        <div className="font-bold text-2xl"> &#8381;/<span dangerouslySetInnerHTML={{ __html: service.priceUnit }} /></div>
                    </div>
                    
                </div>
            ))}
        </div>
    )
}

export default Services;