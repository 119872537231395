import React from 'react';

const gridSizeToWidthClass = {
    '1/4': 'col-span-3',
    '1/3': 'col-span-4',
    '1/2': 'col-span-6',
    '1': 'col-span-12'
};

const ProjectImage = ({ id, text, position, imageUrl, gridSize }) => {
    return (
        <div className={` ${gridSizeToWidthClass[gridSize]} `}>
            <div className="flex flex-col">
                <img 
                    src={process.env.REACT_APP_API_URL + imageUrl} 
                    alt={text} 
                    className=" object-cover w-full h-full " />
                {text && <p className="text-xs lg:text-base pt-1 lg:pt-2">{text}</p>}
            </div>
        </div>
    );
};

export default ProjectImage;
