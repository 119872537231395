import React, {useRef, useState, useCallback, useEffect} from 'react';


/**
 * Animated Cursor
 * Replaces the native cursor with a custom animated cursor.
 *
 * @author Stephen Scaff
 */
const AnimatedCursor = ({
    color = '96, 96, 96',
    outerAlpha = 0.4,
    innerSize = 10,
    outerSize = 10,
    outerScale = 4,
    innerScale = 0.7
}) => {
    const cursorStyles = {
        cursor: 'none'
    };
    
    const cursorOuterRef = useRef()
    const cursorInnerRef = useRef()
    const requestRef = useRef()
    const previousTimeRef = useRef()
    const [coords, setCoords] = useState({ x: 0, y: 0 })
    const [isVisible, setIsVisible] = useState(true)
    const [isActive, setIsActive] = useState(false)
    const [isActiveClickable, setIsActiveClickable] = useState(false)
    let endX = useRef(0)
    let endY = useRef(0)

    useEffect(() => {
        // Apply the styles
        const applyStyles = (element) => {
            for (let style in cursorStyles) {
                element.style[style] = cursorStyles[style];
            }
        };
    
        applyStyles(document.documentElement); // for <html>
        applyStyles(document.body);
    
        return () => {
            // Remove the styles on unmount
            console.log(cursorStyles)
            for (let style in cursorStyles) {
                console.log(style)
                document.documentElement.style[style] = null;
                document.body.style[style] = null;
            }
        };
    }, []);

    function useEventListener(eventName, handler, element = document) {
        const savedHandler = useRef()
    
        useEffect(() => {
            savedHandler.current = handler
        }, [handler])
    
        useEffect(() => {
            const isSupported = element && element.addEventListener
            if (!isSupported) return
    
            const eventListener = (event) => savedHandler.current(event)
    
            element.addEventListener(eventName, eventListener)
    
            return () => {
                element.removeEventListener(eventName, eventListener)
            }
        }, [eventName, element])
    }

    const onMouseMove = useCallback(({ clientX, clientY }) => {
        setCoords({ x: clientX, y: clientY })
        cursorInnerRef.current.style.top = clientY + 'px'
        cursorInnerRef.current.style.left = clientX + 'px'
        endX.current = clientX
        endY.current = clientY
    }, [])

    const animateOuterCursor = useCallback(
        (time) => {
            if (previousTimeRef.current !== undefined) {
                coords.x += (endX.current - coords.x) / 8
                coords.y += (endY.current - coords.y) / 8
                cursorOuterRef.current.style.top = coords.y + 'px'
                cursorOuterRef.current.style.left = coords.x + 'px'
            }
            previousTimeRef.current = time
            requestRef.current = requestAnimationFrame(animateOuterCursor)
        },
        [requestRef] // eslint-disable-line
    )
    const cleanupAnimation = () => {
        if (requestRef.current) {
            cancelAnimationFrame(requestRef.current);
        }
    };

    useEffect(() => {
        requestRef.current = requestAnimationFrame(animateOuterCursor)
        return () => {
            cleanupAnimation();
        };
    })

    const onMouseDown = useCallback(() => setIsActive(true), [])
    const onMouseUp = useCallback(() => setIsActive(false), [])
    const onMouseEnter = useCallback(() => setIsVisible(true), [])
    const onMouseLeave = useCallback(() => setIsVisible(false), [])

    useEventListener('mousemove', onMouseMove, document)
    useEventListener('mousedown', onMouseDown, document)
    useEventListener('mouseup', onMouseUp, document)
    useEventListener('mouseenter', onMouseEnter, document)
    useEventListener('mouseleave', onMouseLeave, document)

    useEffect(() => {
        if (isActive) {
            cursorInnerRef.current.style.transform = `scale(${innerScale})`
            cursorOuterRef.current.style.transform = `scale(${outerScale})`
        } else {
            cursorInnerRef.current.style.transform = 'scale(1)'
            cursorOuterRef.current.style.transform = 'scale(1)'
        }
    }, [innerScale, outerScale, isActive])

    useEffect(() => {
        if (isActiveClickable) {
            cursorInnerRef.current.style.transform = `scale(${innerScale * 1.3})`
            cursorOuterRef.current.style.transform = `scale(${outerScale * 1.4})`
        }
    }, [innerScale, outerScale, isActiveClickable])

    useEffect(() => {
        if (isVisible) {
            cursorInnerRef.current.style.opacity = 1
            cursorOuterRef.current.style.opacity = 1
        } else {
            cursorInnerRef.current.style.opacity = 0
            cursorOuterRef.current.style.opacity = 0
        }
    }, [isVisible])

    useEffect(() => {
        const clickables = document.querySelectorAll(
            'a, input[type="submit"], input[type="image"], label[for], select, button, .link'
        )
        clickables.forEach((el) => {
            el.style.cursor = 'none'

            el.addEventListener('mouseover', () => {
                setIsActive(true)
            })
            el.addEventListener('click', () => {
                setIsActive(true)
                setIsActiveClickable(false)
            })
            el.addEventListener('mousedown', () => {
                setIsActiveClickable(true)
            })
            el.addEventListener('mouseup', () => {
                setIsActive(true)
            })
            el.addEventListener('mouseout', () => {
                setIsActive(false)
                setIsActiveClickable(false)
            })
        })

        return () => {
            clickables.forEach((el) => {
                el.removeEventListener('mouseover', () => {
                    setIsActive(true)
                })
                el.removeEventListener('click', () => {
                    setIsActive(true)
                    setIsActiveClickable(false)
                })
                el.removeEventListener('mousedown', () => {
                    setIsActiveClickable(true)
                })
                el.removeEventListener('mouseup', () => {
                    setIsActive(true)
                })
                el.removeEventListener('mouseout', () => {
                    setIsActive(false)
                    setIsActiveClickable(false)
                })
            })
        }
    }, [isActive])

    const styles = {
        cursor: {
            zIndex: 999,
            position: 'fixed',
            opacity: 1,
            pointerEvents: 'none',
            transition: 'opacity 0.15s ease-in-out, transform 0.15s ease-in-out'
        },
        cursorInner: {
            zIndex: 999,
            position: 'fixed',
            borderRadius: '50%',
            width: innerSize,
            height: innerSize,
            pointerEvents: 'none',
            backgroundColor: `rgba(${color}, 1)`,
            transition: 'opacity 0.15s ease-in-out, transform 0.25s ease-in-out'
        },
        cursorOuter: {
            zIndex: 999,
            position: 'fixed',
            borderRadius: '50%',
            pointerEvents: 'none',
            width: outerSize,
            height: outerSize,
            backgroundColor: `rgba(${color}, ${outerAlpha})`,
            transition: 'opacity 0.15s ease-in-out, transform 0.15s ease-in-out'
        }
    }

    return (
        <div>
            <div ref={cursorOuterRef} style={styles.cursorOuter} />
            <div ref={cursorInnerRef} style={styles.cursorInner} />
        </div>
    )
}


export default AnimatedCursor;