// Toast.js
import React from 'react';
import { FaInfo, FaCircleCheck, FaCircleExclamation, FaXmark } from 'react-icons/fa6';
import { useToast } from './../ToastContext';

const Toast = () => {
  const { toasts, removeToast } = useToast();

  return (
    <>
      {toasts.map(toast => (
        <SingleToast
          key={toast.id}
          {...toast}
          onClose={() => removeToast(toast.id)}
        />
      ))}
    </>
  );
};

const SingleToast = ({ id, message, type, onClose, autoHide, afterClose }) => {
  React.useEffect(() => {
    if (autoHide) {
      const timer = setTimeout(() => {
        onClose();
        if (afterClose) afterClose();
      }, 3000); // auto-hide after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [autoHide, onClose, afterClose]);

  const getBackgroundColor = () => {
    switch (type) {
      case 'error': return 'bg-red-500';
      case 'success': return 'bg-green-500';
      default: return 'bg-gray-500';
    }
  };

  const getIcon = () => {
    switch (type) {
      case 'error': return <FaCircleExclamation size='1.2em'/>;
      case 'success': return <FaCircleCheck size='1.2em'/>;
      default: return <FaInfo size='1.2em'/>;
    }
  };

  const handleClose = () => {
    onClose();
    if (afterClose) afterClose();
  };

  return (
    <div className={`flex items-center p-4 ${getBackgroundColor()} text-white fixed bottom-0 right-0 mb-4 mr-4 rounded-md shadow-lg transform transition-all ease-out duration-300 ${type === 'error' ? 'scale-105' : 'scale-100'}`}>
      <span className="mr-2">{getIcon()}</span>
      {message}
      { (!autoHide) ? <button className="ml-6" onClick={handleClose}><FaXmark/></button> : ''}
    </div>
  );
};

export default Toast;
