import React, { useRef, useState, useEffect } from 'react';
import axios from 'axios';
import { useToast } from './../../ToastContext';
import { FaDeleteLeft } from 'react-icons/fa6';
import ConfirmDialog from './../ConfirmDialog';


function ImageUploader({ section, multiple = false, onUploadSuccess, onUploadError }) {
    const [images, setImages] = useState([]);
    const { addToast } = useToast();
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [currentIndexToRemove, setCurrentIndexToRemove] = useState(null);

    useEffect(() => {
        // Fetch the images on component mount
        const fetchPost = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/imagecontent/${section}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setImages(response.data.imageContent);
            } catch (e) {
                console.error("There was an error fetching the images:", e);
                addToast(e.message, "error", false);
            }
        };
        fetchPost();
    }, [section,addToast]);

    const inputRef = useRef(null);

    const handleClick = () => {
        inputRef.current.click();
    };

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);

        // Prepare form data
        const formData = new FormData();
        files.forEach(file => {
            formData.append(e.target.name, file);
        });
        // formData.append('section', section);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/imagecontent`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });


            if (section === 'bio') {
                setImages(response.data.images);
            } else if (section === 'gallery') {
                setImages(prevImages => [...(prevImages || []), ...response.data.images]);
            }

        } catch (error) {
            addToast(error.message || 'Upload failed.', "error", false);
        }
    };

    const handleRemoveImage = (imageId) => {
        setCurrentIndexToRemove(imageId); // Save the index for later
        setDialogOpen(true); // Open the dialog
    }
    const handleRemoveImageConfirm = async () => {
        if ( images[currentIndexToRemove].id) {
            // try {
            //     await axios.delete(`${process.env.REACT_APP_API_URL}/api/imagecontent/${images[currentIndexToRemove].id}`, {
            //         headers: {
            //             Authorization: `Bearer ${localStorage.getItem('token')}`
            //         }
            //     });
            //     const updatedImages = images.filter((_, index) => index !== currentIndexToRemove);
            //     setImages(updatedImages);
            //     setDialogOpen(false);  // Close the dialog
            //     addToast("Изображение удалено", "success", true);
            // } catch (e) {
            //     console.log(e)
            //     addToast(e, "error", false);
            //     return false;
            // }
            axios.delete(`${process.env.REACT_APP_API_URL}/api/imagecontent/${images[currentIndexToRemove].id}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }).then(() => {
                console.log(images,currentIndexToRemove)
                const updatedImages = images.filter((_, index) => index !== currentIndexToRemove);
                setImages(updatedImages);
                
                addToast("Изображение удалено", "success", true);
            }).catch( (e) => {
                console.log(e)
                addToast(e.message, "error", false);
            }).finally(()=>{
                setDialogOpen(false);  // Close the dialog
            })
        }
        
    }


    return (
        <div className='w-full '>
            <input
                type="file"
                ref={inputRef}
                name={section}
                style={{ display: 'none' }}
                multiple={multiple}
                onChange={handleFileChange}
            />

            <div className={`uploaded-images my-6 grid gap-4 grid-cols-${multiple ? '6' : '1'}`}>
                {images.map((image,index) => (
                    <div className='relative' key={image.id}>
                        {multiple ?
                        <button
                            className="absolute right-0 top-0  text-red-500 hover:text-red-700 font-bold  py-2 ml-4 rounded focus:outline-none focus:shadow-outline"
                            tooltip='удалить'
                            onClick={() => handleRemoveImage(index)}>
                            <FaDeleteLeft size='1.6em' />
                        </button>
                        :''}
                        <img
                            className='object-cover w-full h-full pointer-events-none'
                            src={process.env.REACT_APP_API_URL + image.imageUrl} alt={image.description || "Uploaded content"} />
                    </div>
                ))}
            </div>
            
            <button
                className='py-2 mb-6 bg-blue-500 hover:bg-blue-700 text-white font-bold  px-4 rounded focus:outline-none focus:shadow-outline'
                onClick={handleClick}>
                Загрузить фото
            </button>

            <ConfirmDialog
                isOpen={isDialogOpen}
                title="Удалить изображение"
                message="Вы уверены что хотите удалить изображение?"
                onConfirm={handleRemoveImageConfirm}
                onCancel={() => setDialogOpen(false)}
            />
        </div>
    );
}

export default ImageUploader;
