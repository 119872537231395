import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';
import { RxHamburgerMenu, RxDividerHorizontal } from 'react-icons/rx';

const Header = () => {

    const links = [
        { slug: '/#top', text: 'Главная' },
        { slug: '/#whywe', text: 'Почему мы?' },
        { slug: '/#recent-projects', text: 'Портфолио' },
        { slug: '/#services-we-provide', text: 'Услуги' }
    ]

    const [isOpen, setIsOpen] = useState(false);

    return (
        <header className="flex justify-between px-8 py-6 lg:px-24 lg:py-12">
            <Link to={`/`}>
                <div className="font-[Gothic] text-3xl lg:text-4xl">
                    Architerius
                </div>
            </Link>
            <nav className='relative'>
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className='fixed top-0 right-0 mx-8 my-6 lg:mx-24 lg:my-12 z-50 transition-all duration-1000'
                >
                    {isOpen ? <RxDividerHorizontal className='text-white' size='2em' /> : <RxHamburgerMenu size='2em' />}
                </button>
                <div
                    className={`fixed z-40 top-0 left-0 w-screen bg-stone-800 overflow-hidden transition-all duration-500 ease-in-out ${isOpen ? 'h-auto max-h-screen' : 'h-0 max-h-0'}`}
                >
                    
                    <div className="grid grid-cols-1 h-screen px-8 py-6 lg:px-24 lg:py-12 ">
                        <div>
                            <Link to={`/`}>
                                <div className="font-[Gothic] text-white text-3xl lg:text-4xl ">
                                    Architerius
                                </div>
                            </Link>
                        
                            <div className='flex flex-col justify-center pt-24 items-start'>
                                { links.map( (link, index) => (
                                    <Link
                                        to={link.slug}
                                        key={index}
                                        className="text-white font-bold text-4xl lg:text-6xl mt-4 lg:mt-6"
                                        onClick={() => setIsOpen(false)}
                                    >
                                        {link.text}
                                    </Link>
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-col grow justify-self-start place-self-end'>
                            <div className="text-white font-normal flex flex-col gap-2 text-xl lg:text-xl pb-12">
                                <a href="mailto:architerius-art@yandex.ru">architerius-art@yandex.ru</a>
                                <p>+7 985 127-28-30</p>
                                <a href={`https://www.instagram.com/architerius/`} rel='noreferrer' target="_blank">
                                <div className='flex gap-2 items-center'>
                                    <FaInstagram />
                                    architerius
                                </div>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

        </header>
    )
}

export default Header;