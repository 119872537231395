// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../assets/fonts/ITCAvantGardeGothicBookRegular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../assets/fonts/Geometria/geometria.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./../assets/fonts/Geometria/geometria-bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./../assets/fonts/Geometria/geometria-italic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Gothic';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
}

@font-face {
    font-family: 'Geometria';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
    font-weight: 400;
    /* Regular */
    font-style: normal;
}

@font-face {
    font-family: 'Geometria';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('opentype');
    font-weight: 700;
    /* Bold */
    font-style: normal;
}

@font-face {
    font-family: 'Geometria';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('opentype');
    font-weight: 400;
    /* Italic */
    font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,+DAAmF;AACvF;;AAEA;IACI,wBAAwB;IACxB,+DAAwE;IACxE,gBAAgB;IAChB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,+DAA6E;IAC7E,gBAAgB;IAChB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,wBAAwB;IACxB,+DAA+E;IAC/E,gBAAgB;IAChB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'Gothic';\n    src: url('./../assets/fonts/ITCAvantGardeGothicBookRegular.otf') format('opentype');\n}\n\n@font-face {\n    font-family: 'Geometria';\n    src: url('./../assets/fonts/Geometria/geometria.ttf') format('opentype');\n    font-weight: 400;\n    /* Regular */\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Geometria';\n    src: url('./../assets/fonts/Geometria/geometria-bold.ttf') format('opentype');\n    font-weight: 700;\n    /* Bold */\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Geometria';\n    src: url('./../assets/fonts/Geometria/geometria-italic.ttf') format('opentype');\n    font-weight: 400;\n    /* Italic */\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
