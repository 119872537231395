import React from 'react';
import { ReactCompareSlider, ReactCompareSliderImage, ReactCompareSliderHandle } from 'react-compare-slider';

const tailwindColors = require('tailwindcss/colors')

const CompareSlider = ({planBefore, planAfter}) => {
    return (
        <div className='px-8 lg:px-24 mb-12'>
            <div className='flex justify-between text-xs lg:text-base text-stone-400 mb-2'>
                <p>Было</p>
                <p>Стало</p>
            </div>
            <ReactCompareSlider
                style={{
                    width: '100%',
                }}
                handle={
                    <ReactCompareSliderHandle
                        buttonStyle={{display: 'none'}}
                        linesStyle={{height: '100%', width: 2, color: tailwindColors.slate[700]}}
                    />
                }
                changePositionOnHover="true"
                itemOne={<ReactCompareSliderImage src={planBefore} alt=" " />}
                itemTwo={<ReactCompareSliderImage src={planAfter} alt=" " />}
            />
        </div>
    )
}

export default CompareSlider