import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const AdminHeader = ({ username }) => {

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        navigate('/login');
    };

    return (
        <header className="bg-gray-800 w-full px-12 py-2 flex justify-between items-center">
            <Link to={`/admin`}>
                <h1 className="text-white text-xl font-bold">ARCHITERIUS | Админка</h1>
            </Link>
            <div className="flex items-center">
                <p className="text-white text-sm mr-4">давно тебя не было в уличных гонках, {username}</p>
                <button
                    onClick={handleLogout}
                    className=" hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                    Logout
                </button>
            </div>
        </header>
    );
};

export default AdminHeader;
