import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="p-8 pt-24  lg:px-24">
            <div className="flex flex-wrap justify-between items-center border-t-2 border-stone-900 pt-12">
                <Link to={`/`}><div className="font-[Gothic] text-3xl lg:text-4xl pb-6">Architerius</div></Link>
                <div className="font-normal text-l lg:text-xl pb-6">
                    <a href="mailto:architerius-art@yandex.ru">architerius-art@yandex.ru</a>
                    <p>+7 985 127-28-30</p>
                    <a href={`https://www.instagram.com/architerius/`} rel='noreferrer' target="_blank">
                        <div className='flex gap-1 items-center'>
                            <FaInstagram />
                            architerius
                        </div>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;