import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import ProjectImage from '../projectPage/ProjectImage'
import CompareSlider from '../projectPage/CompareSlider'
import useDocumentTitle from '../../hooks/useDocumentTitle';

const ProjectPage = () => {
    const [project, setProject] = useState('');

    const [error, setError] = useState('');

    const { projectSlug } = useParams();

    const [documentTitle, setDocumentTitle] = useState(process.env.REACT_APP_SITE_TITLE);
    useDocumentTitle(documentTitle);

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/projects/${projectSlug}`);
                const data = response.data;

                if (data && data.post) {
                    data.post.images && data.post.images.sort((a, b) => a.position - b.position)
                    setProject(data.post);
                    setDocumentTitle(data.post.title);  
                }
            } catch (err) {
                setError('Error fetching project');
            }
        };
        fetchProject();
        
    }, [projectSlug]);


    return (
        <div className="font-[Geometria] min-h-screen text-stone-900 bg-crystal-cut selection:bg-stone-800 selection:text-white antialiased scroll-smooth">

            <Header />

            {project ? (
                <div>
                    <h1 className="pt-16 lg:pt-0 px-8 lg:px-24 font-bold text-2xl lg:text-3xl mb-6 lg:mb-12">
                        <span dangerouslySetInnerHTML={{ __html: project.title }} />
                    </h1>

                    <img
                        className="object-cover pr-8 pl-8 lg:pr-24 lg:pl-0 mb-6 lg:mb-12 h-[60vh] lg:h-[80vh] w-full"
                        alt={project.title}
                        src={process.env.REACT_APP_API_URL + project.imageUrl} />


                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-12 text-m lg:text-lg px-8 lg:px-24 mb-12'>
                        <div className=''>{project.content}</div>
                        <div>
                            <div className='flex gap-4 '>
                                <p className='text-stone-400 shrink-0 w-24 lg:w-36'>Площадь</p>
                                <p className=''>{project.area} м<sup>2</sup></p>
                            </div>
                            <div className='flex gap-4 '>
                                <p className='text-stone-400 shrink-0 w-24 lg:w-36'>Тип</p>
                                <p className=''>{project.type}</p>
                            </div>
                            <div className='flex gap-4 '>
                                <p className='text-stone-400 shrink-0 w-24 lg:w-36'>Год</p>
                                <p className=''>{project.year}</p>
                            </div>
                        </div>
                    </div>


                    {(project.planBeforeUrl && project.planAfterUrl) &&
                        <CompareSlider
                            planBefore={process.env.REACT_APP_API_URL + project.planBeforeUrl}
                            planAfter={process.env.REACT_APP_API_URL + project.planAfterUrl}    
                        />
                    }

                    <div className="grid grid-cols-12 gap-3 lg:gap-6 px-8 lg:px-24 ">
                        {project.images?.map((image, index) => (
                            <ProjectImage
                                key={image.imageUrl}
                                index={index}
                                id={index}
                                text={image.description}
                                imageUrl={image.imageUrl}
                                gridSize={image.gridSize}
                                position={image.position}
                            />
                        ))}
                    </div>

                </div>
            ) : (
                <div className='flex place-content-center'>
                    <svg className="animate-spin h-5 w-5 mr-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Loading...
                </div>
            )}

            {error && <p className="text-red-500 pl-24">{error}</p>}

            <Footer />
        </div>
    )
}

export default ProjectPage;