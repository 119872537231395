import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';


const gridSizeToWidthClass = {
    '1/4': 'w-1/4',
    '1/3': 'w-1/3',
    '1/2': 'w-1/2',
    '1': 'w-full'
};

const ImageForm = ({ id, text, moveImage, imageUrl, gridSize, handleGridSizeChange, handleDescriptionChange, handleDelete }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'image',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.id;
            const hoverIndex = id;

            if (dragIndex === hoverIndex) {
                return;
            }

            moveImage(dragIndex, hoverIndex);

            item.id = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: "image", 
        item: { type: 'image', id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <div ref={ref} className={`opacity-${isDragging ? '0' : 'full'} ${gridSizeToWidthClass[gridSize]}   `}>
            <div className="rounded-lg shadow-lg p-6 mt-4 mr-2 bg-white flex flex-col">
                <div className="flex flew-row justify-items-stretch items-baseline">
                    <button type="button" onClick={() => handleDelete(id)} className="bg-red-500 text-white mr-2 rounded p-1 hover:bg-red-600">Delete</button>
                    <select 
                        value={gridSize} 
                        className="mt-4 p-2 border border-gray-300 rounded w-full"
                        onChange={(e) => handleGridSizeChange(id, e.target.value)} >
                        <option value="1/4">1/4</option>
                        <option value="1/3">1/3</option>
                        <option value="1/2">1/2</option>
                        <option value="1">1</option>
                    </select>
                </div>
                <div className=" flex flew-row justify-center">
                    <img 
                        src={process.env.REACT_APP_API_URL + imageUrl} 
                        alt=''
                        className="mt-4 rounded object-cover max-h-60 pointer-events-none" />
                </div>
                <input 
                    type="text" 
                    value={text} 
                    className="mt-4 p-2 border border-gray-300 rounded w-full"
                    onChange={(e) => handleDescriptionChange(id, e.target.value)} />
                
            </div>
        </div>
    );
};

export default ImageForm;
