import React, { useState, useEffect } from 'react';
import { useToast } from './../../ToastContext';
import axios from 'axios';

const TextContentForm = ({ identifier, isInput = false }) => {
    const { addToast } = useToast();
    const [textContent, setTextContent] = useState({
        identifier: identifier,
        content: ''
    });

    useEffect(() => {
        const fetchTextContent = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/textcontent/${identifier}`);
                if (response.data) {
                    setTextContent(response.data.textContent);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchTextContent();
    }, [identifier]);

    const handleChange = (event) => {
        setTextContent({
            ...textContent,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const tcData = {
            identifier:textContent.identifier, 
            content: textContent.content
        }
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/textcontent/${identifier}`, tcData, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (response.status === 200) {
                console.log('Content updated successfully');
                addToast("Сохранено", "success", true);
            }
        } catch (error) {
            console.log('Error updating content', error);
            addToast("Ошибка сохранения", "error", false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="w-full ">
            {/* <label className="block text-sm  mb-2"> */}
            {isInput 
            ?
                <input 
                    name="content" 
                    value={textContent.content} 
                    onChange={handleChange} 
                    className="shadow appearance-none border rounded w-full py-2 px-3  text-grey-darker  leading-tight focus:outline-none focus:shadow-outline my-6" />
            : 
                <textarea 
                    name="content" 
                    value={textContent.content} 
                    onChange={handleChange} 
                    className="shadow appearance-none border rounded w-full py-2 px-3  text-grey-darker h-20 leading-tight focus:outline-none focus:shadow-outline my-6" />
            }
            <button type="submit" className="bg-blue-500 hover:bg-blue-700 mb-6 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">Сохранить</button>
        </form>
    );
};

export default TextContentForm;
