import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import ImageGallery from '../mainPage/ImageGallery';
import RecentPosts from '../mainPage/RecentPosts';
import Services from '../mainPage/Services';
import Header from '../Header';
import Footer from '../Footer';
import useDocumentTitle from '../../hooks/useDocumentTitle';


const MainPage = () => {
    const location = useLocation();
    useDocumentTitle();

    const [error, setError] = useState("");
    const [companyDesc, setCompanyDesc] = useState("");
    const [whyweTitle, setWhyweTitle] = useState("");
    const [whywe, setWhywe] = useState("");
    const [bio, setBio] = useState("");
    const [bioImage,setBioImage] = useState({});

    useEffect( () => {
        const fetchTexts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/textcontent`);
                const data = response.data.textContents;
                if (data){
                    const companyDescData = data.find((item)=>item.identifier === "company-description")
                    if (companyDescData) {setCompanyDesc( companyDescData)}
                    
                    const whyWeTitleData = data.find((item)=>item.identifier === "whywe-title")
                    if (whyWeTitleData) {setWhyweTitle( whyWeTitleData)}

                    const whyWeData = data.find((item)=>item.identifier === "whywe")
                    if (whyWeData) {setWhywe( whyWeData)}
                    
                    const bioData = data.find((item)=>item.identifier === "bio")
                    if (bioData) {setBio( bioData)}
                }
            } catch (err) {
                setError('Error fetching texts');
            }
        };
        fetchTexts();
    }, [])
    
    useEffect( () => {
        const fetchGallery = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/imagecontent/bio`);
                const data = response.data.imageContent;
                if (data && data[0] && data[0].imageUrl) {
                    setBioImage(process.env.REACT_APP_API_URL + data[0].imageUrl)
                }
            } catch (err) {
                setError('Ошибка загрузки фото');
                // addToast("", "error", false);
            }
        };
        fetchGallery();
    }, [])
    

    useEffect(() => {
        if (location.hash) {
            
            const element = document.querySelector(location.hash);
            if (element) {
                window.scrollTo({
                    top: element.offsetTop,
                    behavior: 'smooth',
                });
            } else if (location.hash === '#top') {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [location]);

    return (
        <div className="font-[Geometria] text-stone-900 bg-crystal-cut selection:bg-stone-800 selection:text-white antialiased scroll-smooth">
            {/* 1. Header with logo and menu button */}
            <Header />
            {error && <p className="text-red-500 pl-24">{error}</p>}
            {/* 2. Company description */}
            <section id="" className="min-h-[60vh] lg:w-4/6 flex items-center justify-start p-8 lg:p-24">
                <p className="whitespace-pre-line font-bold text-[1.75rem] leading-[2rem] lg:text-[2.75rem] lg:leading-[3rem] text-start">
                   {companyDesc.content}
                </p>
            </section>

            {/* 3. Image gallery */}
            <section className="h-[60vh] lg:h-[80vh] pr-8 lg:pr-24">
                <ImageGallery />
            </section>

            {/* 4. Text block about unique interiors */}
            <section id="whywe" className="min-h-[60vh] lg:w-4/6 flex items-center justify-start p-8 lg:p-24">
                <div>
                    <h2 className="font-bold text-2xl lg:text-3xl mb-12">{whyweTitle.content}</h2>
                    <p className="font-normal text-xl">
                        {whywe.content}
                    </p>
                </div>
            </section>

            {/* 5. Recent projects carousel */}
            <section id="recent-projects" className="h-[80vh]  flex flex-col">
                <h2 className="font-bold text-xl pl-8 lg:pl-24 mb-4">Последние проекты</h2>
                <RecentPosts />
            </section>

            {/* 6. Services we provide */}
            <section id="services-we-provide" className="pt-16 px-8 lg:px-24">
                <h2 className="font-bold text-xl mb-4">Услуги</h2>
                <Services />
            </section>

            {/* 7. Founder's quote */}
            <section className="flex flex-wrap lg:flex-nowrap items-center gap-4 lg:gap-24 justify-center pt-16 px-8 lg:px-24">
                <div className="basis-full lg:basis-2/6">

                    <img
                        className="object-cover w-full h-full"
                        alt="Ольга Сидорова"
                        src={bioImage} />
                </div>
                <div className="basis-full lg:basis-4/6">
                    <div className='pt-6 lg:p-12'>
                        <p className='font-sans text-l'>{bio.content}</p>
                        <p className='font-bold font-sans text-xl pt-4 lg:pt-12'>Ольга Сидорова</p>
                    </div>
                </div>
            </section>

            {/* 8. Footer */}
            <Footer />
            
        </div>
    );
};

export default MainPage;
