import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const DRAG_TYPE = 'POST';
const DraggablePostRow = ({ post, index, movePost }) => {
    const ref = React.useRef(null);
    const [, drop] = useDrop({
        accept: DRAG_TYPE,
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            movePost(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: DRAG_TYPE,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));

    return (
        <tr ref={ref} key={post.id} className="h-24 border-gray-300 border-b" style={{ opacity: isDragging ? 0.5 : 1 }}>
            <td className="text-sm pr-6"><button className="mr-4">&#x2630;</button>{post.title}</td>
            <td className="text-sm pr-6">{post.year}</td>
            <td className="text-sm pr-6">{post.type}</td>
            <td className="text-sm pr-6">{post.status}</td>
        </tr>
    );
};


const Posts = () => {
    const [posts, setPosts] = useState([]);
    const [error, setError] = useState('');

    const [isEditingOrder, setIsEditingOrder] = useState(false);
    const [orderedPosts, setOrderedPosts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/posts`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = response.data;
                if (data && data.posts) {
                    const sortedPosts = data.posts.sort((a, b) => a.order - b.order);
                    setPosts(sortedPosts);
                }
            } catch (err) {
                setError('Error fetching posts');
            }
        };
        fetchPosts();
    }, []);

    const movePost = (fromIndex, toIndex) => {
        const updatedOrderedPosts = [...orderedPosts];
        const [movedPost] = updatedOrderedPosts.splice(fromIndex, 1);
        updatedOrderedPosts.splice(toIndex, 0, movedPost);
        setOrderedPosts(updatedOrderedPosts);
    };

    const saveOrder = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/posts/reorder`, {
                posts: orderedPosts.map(post => post.id)
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setPosts(orderedPosts);
            setIsEditingOrder(false);
        } catch (err) {
            console.error("Error saving order:", err);
        }
    };

    return (
        <div>
            <h2 className="text-2xl font-semibold my-4">Проекты</h2>
            <button 
                onClick={() => navigate(`/admin/post/`)} 
                className="bg-blue-500 hover:bg-blue-700 mb-6 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
                Новый проект
            </button>
            {!isEditingOrder && (
                <button 
                    onClick={() => { setIsEditingOrder(true); setOrderedPosts(posts); }} 
                    className="bg-orange-500 hover:bg-orange-600 mb-6 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2">
                    Изменить порядок
                </button>
            )}
            {isEditingOrder && (
                <>
                    <button onClick={saveOrder} className="bg-green-500 hover:bg-green-700 mb-6 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2">Сохранить</button>
                    <button onClick={() => setIsEditingOrder(false)} className="bg-red-500 hover:bg-red-700 mb-6 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2">Отменить</button>
                </>
            )}

            {error && <p className="text-red-500">{error}</p>}

            <DndProvider backend={HTML5Backend}>
                <table className="min-w-full bg-white">
                    <thead>
                        <tr className="w-full h-16 border-gray-300 border-b py-6">
                            <th className="text-left text-gray-500">Название</th>
                            <th className="text-left text-gray-500">Год</th>
                            <th className="text-left text-gray-500">Тип</th>
                            <th className="text-left text-gray-500">Статус</th>
                        </tr>
                    </thead>
                    <tbody>
                    {(isEditingOrder ? orderedPosts : posts).map((post, index) => (
                            isEditingOrder 
                                ?
                                    <DraggablePostRow key={post.id} post={post} index={index} movePost={movePost} />
                                : 
                                   (
                                        <tr key={post.id} className="h-24 border-gray-300 border-b">
                                            <td className="text-sm pr-6"><Link to={`/admin/post/${post.id}`} className="text-indigo-500 mr-4">{post.title}</Link></td>
                                            <td className="text-sm pr-6">{post.year}</td>
                                            <td className="text-sm pr-6">{post.type}</td>
                                            <td className="text-sm pr-6">{post.status}</td>
                                        </tr>
                                    )
                                
                    ))}
                    </tbody>
                </table>
                </DndProvider>
        </div>
    )
}

export default Posts;