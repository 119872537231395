import React, { useState, useEffect } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useNavigate, useLocation } from 'react-router-dom';

import 'react-tabs/style/react-tabs.css';

import AdminHeader from './../adminPage/AdminHeader';
import TextContentForm from './../adminPage/TextContent';
import ServiceEditForm from './../adminPage/ServiceEditForm';
import ImageUploader from './../adminPage/ImageUploader';
import Posts from './../adminPage/Posts';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const AdminPage = () => {
    useDocumentTitle();

    const [username, setUsername] = useState('');

    useEffect(() => {
        const usernameFromStorage = localStorage.getItem('username');
        setUsername(usernameFromStorage);
    }, []);

    const navigate = useNavigate();
    const location = useLocation();
    const tabIndex = new URLSearchParams(location.search).get('tab') || 0;

    const handleTabChange = (index) => {
        navigate(`?tab=${index}`);
    };

    return (
        <div className=" ">
            <AdminHeader username={username} />
            <div className='px-12 pt-6 mx-auto'>
                <Tabs defaultIndex={tabIndex} onSelect={handleTabChange}>
                    <TabList>
                        <Tab>Проекты</Tab>
                        <Tab>Описание компании</Tab>
                        <Tab>Галерея</Tab>
                        <Tab>Уникальные интерьеры</Tab>
                        <Tab>Услуги</Tab>
                        <Tab>Биография</Tab>
                    </TabList>

                    <TabPanel>
                        <Posts />
                    </TabPanel>

                    <TabPanel>
                        <h2 className='text-2xl font-semibold my-4'>Описание компании</h2>
                        <TextContentForm identifier="company-description" />
                    </TabPanel>

                    <TabPanel>
                        <h2 className='text-2xl font-semibold my-4'>Галерея</h2>
                        <ImageUploader
                            section="gallery"
                            multiple={true}
                            onUploadSuccess={(data) => console.log('Uploaded:', data)}
                            onUploadError={(error) => console.error('Error:', error)}
                        />
                    </TabPanel>

                    <TabPanel>
                        <TextContentForm identifier="whywe-title" isInput="true" />
                        <TextContentForm identifier="whywe" />
                    </TabPanel>

                    <TabPanel>
                        <h2 className='text-2xl font-semibold my-4'>Услуги</h2>
                        <ServiceEditForm />
                    </TabPanel>

                    <TabPanel>
                        <h2 className='text-2xl font-semibold my-4'>Биография</h2>
                        <div className='grid grid-cols-6 gap-4'>
                            <div className='flex'>
                                <ImageUploader
                                    section="bio"
                                    onUploadSuccess={(data) => console.log('Uploaded:', data)}
                                    onUploadError={(error) => console.error('Error:', error)}
                                />
                            </div>
                            <div className='col-span-5'>
                                <TextContentForm identifier="bio" />
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    );
};

export default AdminPage;
