import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Autoplay, EffectFade } from 'swiper/modules';

import axios from 'axios';

const ImageGallery = () => {
    const [error, setError] = useState("");
    const [images, setImages] = useState([]);

    useEffect(() => {
        const fetchGallery = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/imagecontent/gallery`);
                const data = response.data.imageContent;
                if (data) {
                    setImages(data)
                }
            } catch (err) {
                setError('Ошибка загрузки галереи');
            }
        };
        fetchGallery();
    }, [])



    return (
        <div className='w-full h-full'>
            {error && <p className="text-red-500 pl-24">{error}</p>}
            <Swiper
                spaceBetween={30}
                effect={'fade'}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: true,
                }}
                modules={[Autoplay, EffectFade]}
                className="h-full relative self-center"
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index} className="">
                        <img
                            src={image.imageUrl ? process.env.REACT_APP_API_URL + image.imageUrl : ''}
                            alt=''
                            className="object-cover w-full h-full pointer-events-none" />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
export default ImageGallery;